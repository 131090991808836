import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker, TextField } from '@ecp/features/sales/shared/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { RENTERS_SECONDARY_INSURED_PERSON_REF } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getSapiTarget, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

export const SecondaryNamedInsuredQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Would you like to add another person to this policy as a secondary named insured (SNI)? (optional)',
    trackingName = 'add_sni_selection',
  } = props;

  const secondaryInsuredPersonRef = useField(RENTERS_SECONDARY_INSURED_PERSON_REF);

  const secondaryNamedInsured = useField('person.addSecondaryInsured');

  const sapiTarget = useSelector(getSapiTarget);
  const isSAPIV4 = sapiTarget === 'v4';
  const personQuestionPrefix = isSAPIV4 ? 'person.<id>' : 'personPropertySNI.<id>';
  const usePersonField = useFieldWithPrefix(
    secondaryInsuredPersonRef.props.value,
    personQuestionPrefix,
  );

  const secondaryNamedInsuredFirstName = usePersonField('firstName');
  const secondaryNamedInsuredLastName = usePersonField('lastName');
  const secondaryNamedInsuredDOB = usePersonField('dob');

  useAddFields({ secondaryNamedInsured });

  if (!secondaryNamedInsured.exists) return null;

  return (
    <>
      <RadioGroupWithOptions
        {...secondaryNamedInsured.props}
        label={label}
        id={secondaryNamedInsured.props.name}
        variant='yesNoButton'
        trackingName={trackingName}
      />
      {secondaryNamedInsured.props.value === true && (
        <div>
          <Grid container spacing={1}>
            <GridItem topSpacing='sm' xs={6}>
              <TextField
                {...secondaryNamedInsuredFirstName.props}
                ariaLabel='First Name'
                autoComplete='given-name'
                data-testid='firstName'
                label='First name'
                trackingName='sni_first_name'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={6}>
              <TextField
                {...secondaryNamedInsuredLastName.props}
                ariaLabel='Last Name'
                autoComplete='given-name'
                data-testid='lastName'
                label='Last name'
                trackingName='sni_last_name'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={6}>
              {' '}
              <DatePicker
                {...secondaryNamedInsuredDOB.props}
                autoComplete='bday'
                data-testid='dob'
                hidePicker
                label='Date of birth'
                trackingName='sni_dob'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          </Grid>
        </div>
      )}
    </>
  );
};
